/* eslint-disable jsx-a11y/anchor-is-valid */
/**Validations */
import { useFormik } from 'formik';
import { InscripcionItemRequest, InscripcionRequest } from '../../models/requests';
import { ChangeEvent, useState } from 'react';
import validationSchema from './validationSchema';
/**Utilities */
import { SweetAlert } from '../../utilities';
import { useBecas } from '../../hooks/useBecas';
import TabsComponent from './components/tabs.component';
import InformacionColegioComponent from './components/informacion-colegio.component';
import AsesorComponent from './components/asesor.component';
import AlumnosComponent from './components/alumnos.component';

export const RegisterPage = () => {
  const defaultItem: InscripcionItemRequest = {
    document: '',
    names: '',
    father_last_name: '',
    mother_last_name: '',
    area: '',
    nivel: '',
    categoria: '',
    categorias: [],
  };
  const [activeTab, setActiveTab] = useState<number>(1);
  const defaultFormData: InscripcionRequest = {
    colegio: '',
    cellphone: '',
    direccion: '',
    asesor: '',
    asesor_dni: '',
    asesor_celular: '',
    alumnos: [defaultItem],
  };
  const { storeBeca } = useBecas();
  const [stateView, setStateView] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const currentValidationSchema = validationSchema[activeTab - 1];

  const formik = useFormik({
    initialValues: defaultFormData,
    validationSchema: currentValidationSchema,
    onSubmit: () => {
      if (activeTab !== 3) {
        setActiveTab(activeTab + 1);
      } else {
        storeBeca(formik.values)
          .then((response) => {
            setStateView(true);
            formik.resetForm();
            setActiveTab(1);
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
            SweetAlert.warning('Mensaje', 'Verifique los datos de su inscripción.');
          });
      }
    },
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
  };

  const handleInputItemChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const list = [...formik.values.alumnos];
    (list[index] as any)[name] = value;
    formik.setFieldValue('alumnos', list);
  };

  const handleSelectChangeItem = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    const { name, value } = event.target;
    const list = [...formik.values.alumnos];
    if (name === 'nivel') {
      const categoria = [
        { name: '5 AÑOS', value: '5 AÑOS', nivel: 'INICIAL' },
        { name: '1° GRADO', value: '1° GRADO', nivel: 'PRIMARIA' },
        { name: '2° GRADO', value: '2° GRADO', nivel: 'PRIMARIA' },
        { name: '3° GRADO', value: '3° GRADO', nivel: 'PRIMARIA' },
        { name: '4° GRADO', value: '4° GRADO', nivel: 'PRIMARIA' },
        { name: '5° GRADO', value: '5° GRADO', nivel: 'PRIMARIA' },
        { name: '6° GRADO', value: '6° GRADO', nivel: 'PRIMARIA' },
        { name: '1° SECUNDARIA', value: '1° SECUNDARIA', nivel: 'SECUNDARIA' },
        { name: '2° SECUNDARIA', value: '2° SECUNDARIA', nivel: 'SECUNDARIA' },
        { name: '3° SECUNDARIA', value: '3° SECUNDARIA', nivel: 'SECUNDARIA' },
        { name: '4° SECUNDARIA', value: '4° SECUNDARIA', nivel: 'SECUNDARIA' },
        { name: '5° SECUNDARIA', value: '5° SECUNDARIA', nivel: 'SECUNDARIA' },
      ];

      const categorias = categoria.filter((item) => item.nivel === value);
      (list[index] as any)['categorias'] = categorias;
    }

    (list[index] as any)[name] = value;
    formik.setFieldValue('alumnos', list);
  };

  const devGuillermo = () => {
    window.open('https://guillermosg.dev', '_blank');
  };

  const previousView = () => {
    setActiveTab(activeTab - 1);
  };

  const onAddAlumno = () => {
    formik.setFieldValue('alumnos', [...formik.values.alumnos, defaultItem]);
  };

  const finalizar = () => {
    setStateView(false);
    setErrors([]);
  };

  return (
    <div className="min-vh-100 row justify-content-center align-items-center">
      <div className="col-lg-8 col-md-10 col-12">
        <div className="card card-featured card-featured-primary mb-3 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 mb-3 text-center">
                <img src="/images/logo.png" alt="" className="logo-inscription" />
                <h4 className="mt-3">Inscripción 2024</h4>
              </div>
              {!stateView && (
                <div className="col-md-12 twitter-bs-wizard">
                  <TabsComponent activeTab={activeTab} />
                  <form className="form form-horizontal" onSubmit={formik.handleSubmit}>
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <InformacionColegioComponent formik={formik} handleInputChange={handleInputChange} activeTab={activeTab} />
                      <AsesorComponent formik={formik} handleInputChange={handleInputChange} activeTab={activeTab} />
                      <AlumnosComponent
                        formik={formik}
                        handleInputChange={handleInputChange}
                        activeTab={activeTab}
                        errors={errors}
                        handleInputItemChange={handleInputItemChange}
                        onAddAlumno={onAddAlumno}
                        handleSelectChangeItem={handleSelectChangeItem}
                      />
                    </div>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      {activeTab === 1 ? (
                        <></>
                      ) : (
                        <li className="previous">
                          <button className="btn btn-primary btn-xs" type="button" onClick={previousView}>
                            Atrás
                          </button>
                        </li>
                      )}
                      {activeTab > 0 && activeTab !== 3 && (
                        <li className="next">
                          <button type="submit" className="btn btn-primary btn-xs">
                            Siguiente
                          </button>
                        </li>
                      )}
                      {activeTab === 3 && (
                        <li className="next">
                          <button type="submit" className="btn btn-primary btn-xs">
                            Finalizar
                          </button>
                        </li>
                      )}
                    </ul>
                  </form>
                </div>
              )}
              {stateView && (
                <div className="form-group col-md-12">
                  <div className="alert alert-success alert-dismissible" role="alert">
                    <strong> MENSAJE:</strong> Inscripción registrada correctamente.
                  </div>
                  {/* <p className="m-0">
                      <b>
                        <i className="ri-timer-line"></i> Fecha y hora del examen:
                      </b>{' '}
                      21 de Diciembre del 2023 a las 10:00am
                    </p> */}
                  <br />
                  <p>
                    <i className="ri-information-line"></i> Para más informes escríbenos al whastapp o llámanos al <b>925385486</b>
                  </p>
                </div>
              )}
            </div>
          </div>
          {stateView && (
            <div className="card-footer text-center">
              <button onClick={finalizar} type="button" className="btn btn-success btn-lg btn-block ps-5 pe-5">
                Finalizar inscripción
              </button>
            </div>
          )}
        </div>
        <a onClick={devGuillermo} className="d-block text-center mb-4" role="button">
          @guillermosg.dev
        </a>
      </div>
    </div>
  );
};

export default RegisterPage;
