import api from './api';
/**Models */
import { InscripcionRequest } from '../models/requests';
import { InscripcionResponse } from '../models/responses';

export const storeBeca = async (beca: InscripcionRequest) => {
  try {
    const response = await api.post<InscripcionResponse>('/becas', { beca: beca });
    return response;
  } catch (error) {
    throw error;
  }
};