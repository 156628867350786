/* eslint-disable jsx-a11y/anchor-is-valid */
interface TabsProps {
  activeTab: number;
}
const TabsComponent = (props: TabsProps) => {
  return (
    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
      <li className="nav-item">
        <a className={`${props.activeTab === 1 || props.activeTab > 1 ? 'active ' : ''}nav-link`} role="button">
          <span className="step-number">{props.activeTab > 1 ? <i className="ri-check-line"></i> : <>01</>}</span>
          <span className="step-title">Información del Colegio</span>
        </a>
      </li>
      <li className="nav-item">
        <a className={`${props.activeTab === 2 || props.activeTab > 2 ? 'active ' : ''}nav-link`} role="button">
          <span className="step-number">{props.activeTab > 2 ? <i className="ri-check-line"></i> : <>02</>}</span>
          <span className="step-title">Información Asesor</span>
        </a>
      </li>
      <li className="nav-item">
        <a className={`${props.activeTab === 3 || props.activeTab > 3 ? 'active ' : ''}nav-link`} role="button">
          <span className="step-number">{props.activeTab > 3 ? <i className="ri-check-line"></i> : <>03</>}</span>
          <span className="step-title">Información Alumnos</span>
        </a>
      </li>
    </ul>
  );
};

export default TabsComponent;
