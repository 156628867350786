/* eslint-disable jsx-a11y/anchor-is-valid */
import { InscripcionItemRequest } from '../../../models/requests';
import { ErrorBackend } from '../../../utilities';

interface AsesorComponentProps {
  activeTab: number;
  formik: any;
  handleInputChange: any;
  errors: any;
  handleInputItemChange: any;
  onAddAlumno: any;
  handleSelectChangeItem: any;
}

const AlumnosComponent = (props: AsesorComponentProps) => {
  return (
    <div className={`tab-pane${props.activeTab === 3 ? ' active' : ''}`}>
      <h5>
        Lista de alumnos{' '}
        <a onClick={props.onAddAlumno} role="button" className="add-item-sales">
          <i className="ri-add-line"></i>
        </a>
      </h5>
      {props.formik.values.alumnos.map((alumno: InscripcionItemRequest, index: number) => (
        <div key={index} className="row">
          <div className="col-md-12">
            <h6>Alumno 0{index + 1}</h6>
          </div>
          {/*DNI*/}
          <div className="form-group col-md-6">
            <label htmlFor="document" className="form-label">
              DNI<span className="text-danger">*</span>
            </label>
            <input
              name="document"
              onChange={(e) => props.handleInputItemChange(e, index)}
              value={alumno.document}
              autoComplete="off"
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm' +
                (props.formik.errors.document && props.formik.touched.document ? ' is-invalid' : '')
              }
              id="document"
            />
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.document`} />
          </div>
          {/*NOMBRES*/}
          <div className="form-group col-md-6">
            <label htmlFor="names" className="form-label">
              Nombres<span className="text-danger">*</span>
            </label>
            <input
              name="names"
              onChange={(e) => props.handleInputItemChange(e, index)}
              value={props.formik.values.names}
              autoComplete="off"
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm' +
                (props.formik.errors.names && props.formik.touched.names ? ' is-invalid' : '')
              }
              id="names"
            />
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.names`} />
          </div>
          {/*APELLIDO PATERNO*/}
          <div className="form-group col-md-6">
            <label htmlFor="father_last_name" className="form-label">
              Apellido paterno<span className="text-danger">*</span>
            </label>
            <input
              name="father_last_name"
              onChange={(e) => props.handleInputItemChange(e, index)}
              value={props.formik.values.father_last_name}
              autoComplete="off"
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm' +
                (props.formik.errors.father_last_name && props.formik.touched.father_last_name ? ' is-invalid' : '')
              }
              id="father_last_name"
            />
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.father_last_name`} />
          </div>
          {/*APELLIDOS MATERNO*/}
          <div className="form-group col-md-6">
            <label htmlFor="mother_last_name" className="form-label">
              Apellido materno<span className="text-danger">*</span>
            </label>
            <input
              name="mother_last_name"
              onChange={(e) => props.handleInputItemChange(e, index)}
              value={props.formik.values.mother_last_name}
              autoComplete="off"
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm' +
                (props.formik.errors.mother_last_name && props.formik.touched.mother_last_name ? ' is-invalid' : '')
              }
              id="mother_last_name"
            />
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.mother_last_name`} />
          </div>
          {/**AREA */}
          <div className="form-group col-md-6">
            <label htmlFor="area" className="form-label">
              Área<span className="text-danger">*</span>
            </label>
            <select
              onChange={(e) => props.handleSelectChangeItem(e, index)}
              value={alumno.area ?? ''}
              name="area"
              className={'form-control form-control-sm form-select'}
              id="area"
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="MATEMÁTICA">MATEMÁTICA</option>
              <option value="COMUNICACIÓN">COMUNICACIÓN</option>
              <option value="CIENCIA Y TECNOLOGÍA">CIENCIA Y TECNOLOGÍA</option>
            </select>
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.area`} />
          </div>
          {/**NIVEL */}
          <div className="form-group col-md-6">
            <label htmlFor="nivel" className="form-label">
              Nivel<span className="text-danger">*</span>
            </label>
            <select
              onChange={(e) => props.handleSelectChangeItem(e, index)}
              value={alumno.nivel ?? ''}
              name="nivel"
              className={'form-control form-control-sm form-select'}
              id="nivel"
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="INICIAL">INICIAL</option>
              <option value="PRIMARIA">PRIMARIA</option>
              <option value="SECUNDARIA">SECUNDARIA</option>
            </select>
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.nivel`} />
          </div>
          {/*CATEGORIA*/}
          <div className="form-group col-md-6">
            <label htmlFor="categoria" className="form-label">
              Categoría<span className="text-danger">*</span>
            </label>
            <select
              onChange={(e) => props.handleSelectChangeItem(e, index)}
              value={alumno.categoria ?? ''}
              name="categoria"
              className={'form-control form-control-sm form-select'}
              id="categoria"
            >
              <option value="">Seleccionar</option>
              {alumno.categorias.map((item: any, index: number) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            <ErrorBackend errorsBackend={props.errors} name={`beca.alumnos.${index}.categoria`} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlumnosComponent;
