import { ErrorValidate } from '../../../utilities';

interface InformacionColegioProps {
  activeTab: number;
  formik: any;
  handleInputChange: any;
}

const InformacionColegioComponent = (props: InformacionColegioProps) => {
  return (
    <div className={`tab-pane${props.activeTab === 1 ? ' active' : ''}`}>
      <div className="row">
        {/*COLEGIO*/}
        <div className="form-group col-md-8 col-12">
          <label htmlFor="colegio" className="form-label">
            Institución Educativa<span className="text-danger">*</span>
          </label>
          <input
            name="colegio"
            onChange={props.handleInputChange}
            value={props.formik.values.colegio}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm todo-mayuscula' +
              (props.formik.errors.colegio && props.formik.touched.colegio ? ' is-invalid' : '')
            }
            id="colegio"
          />
          <ErrorValidate state={props.formik.errors.colegio} />
        </div>
        {/*CELULAR*/}
        <div className="form-group col-md-4 col-12">
          <label htmlFor="cellphone" className="form-label">
            Celular<span className="text-danger">*</span>
          </label>
          <input
            name="cellphone"
            onChange={props.handleInputChange}
            value={props.formik.values.cellphone}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm' + (props.formik.errors.cellphone && props.formik.touched.cellphone ? ' is-invalid' : '')
            }
            id="cellphone"
          />
          <ErrorValidate state={props.formik.errors.cellphone} />
        </div>
        {/*DIRECCION*/}
        <div className="form-group col-md-12">
          <label htmlFor="direccion" className="form-label">
            Dirección<span className="text-danger">*</span>
          </label>
          <input
            name="direccion"
            onChange={props.handleInputChange}
            value={props.formik.values.direccion}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm todo-mayuscula' +
              (props.formik.errors.direccion && props.formik.touched.direccion ? ' is-invalid' : '')
            }
            id="direccion"
          />
          <ErrorValidate state={props.formik.errors.direccion} />
        </div>
      </div>
    </div>
  );
};

export default InformacionColegioComponent;
