import * as Yup from 'yup';

const validationSchema = [
  Yup.object().shape({
    colegio: Yup.string().required("El campo 'Institución Educativa' es obligatorio."),
    cellphone: Yup.string()
      .min(9, "El campo 'Celular' debe tener al menos 9 caracteres.")
      .max(9, "El campo 'Celular' debe tener como máximo 9 caracteres.")
      .required("El campo 'Celular' es obligatorio."),
    direccion: Yup.string().required("El campo 'Dirección' es obligatorio."),
  }),
  Yup.object().shape({
    asesor: Yup.string().required("El campo 'Nombres y apellidos' es obligatorio."),
    asesor_dni: Yup.string()
      .min(8, "El campo 'DNI' debe tener al menos 8 caracteres.")
      .max(8, "El campo 'DNI' debe tener como máximo 8 caracteres.")
      .required("El campo 'DNI' es obligatorio."),
    asesor_celular: Yup.string()
      .min(9, "El campo 'Celular' debe tener al menos 9 caracteres.")
      .max(9, "El campo 'Celular' debe tener como máximo 9 caracteres.")
      .required("El campo 'Celular' es obligatorio."),
  }),
];

export default validationSchema;
