/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
/**Components */
import { Loading, LoadingState } from '../components/shared';
/**Reduxs */
import { AppStore } from '../redux/store';

export const PageLayout = () => {
  const [loading, setLoading] = useState(true);
  const loadingState = useSelector((store: AppStore) => store.loading);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  });

  const whatsapp = () => {
    window.open('https://wa.me/+51925385486?&text=Hola', '_blank');
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Tooltip id="tooltip-page" />
      <div id="layout-page" className="container">
        <Outlet />
        {loadingState.isLoading ? <LoadingState /> : null}
      </div>
      <div className="whatsapp-wrap">
        <a onClick={whatsapp} data-tooltip-id="tooltip-page" data-tooltip-content="Más información a nuestro WhatsApp" role="button">
          <img src="/images/whatsapp.svg" alt="Más información" />
        </a>
      </div>
    </>
  );
};

export default PageLayout;
