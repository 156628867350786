import { ErrorValidate } from '../../../utilities';

interface AsesorComponentProps {
  activeTab: number;
  formik: any;
  handleInputChange: any;
}

const AsesorComponent = (props: AsesorComponentProps) => {
  return (
    <div className={`tab-pane${props.activeTab === 2 ? ' active' : ''}`}>
      <div className="row">
        {/*ASESOR*/}
        <div className="form-group col-md-12 col-12">
          <label htmlFor="asesor" className="form-label">
            Nombres y apellidos<span className="text-danger">*</span>
          </label>
          <input
            name="asesor"
            onChange={props.handleInputChange}
            value={props.formik.values.asesor}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm todo-mayuscula' +
              (props.formik.errors.asesor && props.formik.touched.asesor ? ' is-invalid' : '')
            }
            id="asesor"
          />
          <ErrorValidate state={props.formik.errors.asesor} />
        </div>
        {/*DNI*/}
        <div className="form-group col-md-6">
          <label htmlFor="asesor_dni" className="form-label">
            DNI<span className="text-danger">*</span>
          </label>
          <input
            name="asesor_dni"
            onChange={props.handleInputChange}
            value={props.formik.values.asesor_dni}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm todo-mayuscula' +
              (props.formik.errors.asesor_dni && props.formik.touched.asesor_dni ? ' is-invalid' : '')
            }
            id="asesor_dni"
          />
          <ErrorValidate state={props.formik.errors.asesor_dni} />
        </div>
        {/*CELULAR*/}
        <div className="form-group col-md-6">
          <label htmlFor="asesor_celular" className="form-label">
            Celular<span className="text-danger">*</span>
          </label>
          <input
            name="asesor_celular"
            onChange={props.handleInputChange}
            value={props.formik.values.asesor_celular}
            autoComplete="off"
            type="text"
            className={
              'form-control form-control-sm' + (props.formik.errors.asesor_celular && props.formik.touched.asesor_celular ? ' is-invalid' : '')
            }
            id="asesor_celular"
          />
          <ErrorValidate state={props.formik.errors.asesor_celular} />
        </div>
        
      </div>
    </div>
  );
};

export default AsesorComponent;
