import { useDispatch } from "react-redux";
/**Services */
import { InscripcionRequest } from "../models/requests";
import * as becasService from "../services/becas.service";
/**Redux */
import { setLoading } from "../redux/states/loading.slice";

export function useBecas() {
  const dispatch = useDispatch();

  //STORE
  const storeBeca = async (beca: InscripcionRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await becasService.storeBeca(beca);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { storeBeca };
  
}
